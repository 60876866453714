define("installer-portal/components/ui/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='md:z-20 md:sticky md:top-0 space-x-2 flex flex-row justify-between bg-primary-600 h-18 text-white pl-6 xl:pl-12 pt-5 text-base font-bold leading-8'
    data-test-breadcrumbs={{if @name @name true}}
  >
    <div class='flex flex-row pb-5 w-full lg:w-auto pr-6 lg:pr-0'>
      {{yield
        (hash Item=(component 'ui/breadcrumbs/item' name=@name loading=@loading))
      }}
    </div>
    <div class='hidden lg:flex lg:flex-row items-center pb-2 pr-3'>
      <span class='mr-2 text-xs self-end pb-1 font-normal'>{{t
          'internal.linkPrefix'
        }}</span>
      <div class=''>
        {{svg-jar 'logo' class='w-16'}}
      </div>
    </div>
  </div>
  
  {{!-- <div class="md:z-20 md:sticky md:top-0 space-x-2 flex flex-row bg-primary-600 h-18 text-white px-6 xl:px-12 py-5 text-base font-bold leading-8"
    data-test-breadcrumbs={{if @name @name true}}>
    {{yield (hash
      Item=(component "ui/breadcrumbs/item" name=@name loading=@loading)
    )}}
  </div> --}}
  */
  {
    "id": "07Ludz5J",
    "block": "[[[10,0],[14,0,\"md:z-20 md:sticky md:top-0 space-x-2 flex flex-row justify-between bg-primary-600 h-18 text-white pl-6 xl:pl-12 pt-5 text-base font-bold leading-8\"],[15,\"data-test-breadcrumbs\",[52,[30,1],[30,1],true]],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row pb-5 w-full lg:w-auto pr-6 lg:pr-0\"],[12],[1,\"\\n    \"],[18,3,[[28,[37,2],null,[[\"Item\"],[[50,\"ui/breadcrumbs/item\",0,null,[[\"name\",\"loading\"],[[30,1],[30,2]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"hidden lg:flex lg:flex-row items-center pb-2 pr-3\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"mr-2 text-xs self-end pb-1 font-normal\"],[12],[1,[28,[35,4],[\"internal.linkPrefix\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"logo\"],[[\"class\"],[\"w-16\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@name\",\"@loading\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"t\",\"svg-jar\"]]",
    "moduleName": "installer-portal/components/ui/breadcrumbs.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});