define("installer-portal/templates/external/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Yl89b/U",
    "block": "[[[10,0],[14,0,\"min-h-screen bg-white flex relative\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"z-10 justify-start bg-white rounded-lg w-full m-10 sm:m-16 md:m-20 shadow place-self-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mx-auto w-full\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"p-6 sm:px-8 sm:py-16 md:py-20\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n          \"],[10,\"h2\"],[14,\"data-test-title\",\"\"],[14,0,\"text-3xl font-extrabold text-gray-900\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[\"external.maintenance.title\"],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,2],[14,0,\"mt-2 text-sm text-gray-600 max-w\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[\"external.maintenance.description\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mt-8 max-w-sm m-auto\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"mt-6\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"brand\"],[[\"class\"],[\"h-12 w-auto mx-auto text-primary-600\"]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"t\",\"svg-jar\"]]",
    "moduleName": "installer-portal/templates/external/maintenance.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});